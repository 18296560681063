import React, { useState, useEffect } from 'react';
import './App.css'; // Import the CSS file for styling

function App() {
    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = () => {
        // You can add specific image URLs here or fetch them from an API
        const urls = [
            "https://cdn.discordapp.com/attachments/1193817624518332426/1216639023934865429/8F84D0B1-2A68-4FAB-8440-C712968917FD.jpg?ex=66e094a3&is=66df4323&hm=01ddeb15360103d62ad13e302417f47f8bdbe579aa4035e39449602a126c8ce8&",
            'https://cdn.discordapp.com/attachments/1193817624518332426/1216622162119626772/Untitled.png?ex=66e084ef&is=66df336f&hm=61c721652fe49078e4e236f181c1b14b096a4e83389c91595484ad4c58749cbc&',
            "https://cdn.discordapp.com/attachments/1193817624518332426/1216642833390370826/D711AF8B-B4AA-483E-9092-B31146618158.jpg?ex=66e0982f&is=66df46af&hm=af74f20e203e7808c9750e60693a2b18f41fb01283e8f1d064188a036d5c8ed7&",
            // Add more image URLs as needed
        ];
        setImageURLs(urls);
    };

    return (
        <div className="App">
            <h1>
                <marquee>Welcome to HIMBROMA.ME</marquee>
            </h1>
            <div className="image-container">
                {imageURLs.map((url, index) => (
                    <img key={index} src={url} alt={`Image ${index}`}/>
                ))}
            </div>
            <p>Copyright © 2024</p>
        </div>
    );
}

export default App;
